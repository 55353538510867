body{
    /*padding-top:50px*/
}
body>.navbar{
    -webkit-transition:background-color .3s ease-in;
    transition:background-color .3s ease-in
}
@media (min-width:768px){
    body>.navbar-transparent{
        background-color:transparent
    }
    body>.navbar-transparent .navbar-nav>.open>a{
        background-color:transparent!important
    }
}
#home{
    padding-top:0
}
#home .navbar-brand{
    padding:13.5px 15px 12.5px
}
#home .navbar-brand>img{
    display:inline;
    margin:0 10px;
    height:100%
}
#banner{
    min-height:300px;
    border-bottom:none
}
.table-of-contents{
    margin-top:1em
}
.page-header h1{
    font-size:4em
}
.bs-docs-section{
    margin-top:6em
}
.bs-docs-section h1{
    padding-top:100px
}
.bs-component{
    position:relative
}
.bs-component .modal{
    position:relative;
    top:auto;
    right:auto;
    left:auto;
    bottom:auto;
    z-index:1;
    display:block
}
.bs-component .modal-dialog{
    width:90%
}
.bs-component .popover{
    position:relative;
    display:inline-block;
    width:220px;
    margin:20px
}
#source-button{
    position:absolute;
    top:0;
    right:0;
    z-index:100;
    font-weight:700
}
.nav-tabs{
    margin-bottom:15px
}
.progress{
    margin-bottom:10px
}
footer{
    margin:5em 0
}
footer li{
    float:left;
    margin-right:1.5em;
    margin-bottom:1.5em
}
footer p{
    clear:left;
    margin-bottom:0
}
.splash{
    padding:9em 0 2em;
    background-color:#141d27;
    background-size:cover;
    background-attachment:fixed;
    color:#fff;
    text-align:center
}
.splash .logo{
    width:160px
}
.splash h1{
    font-size:3em
}
.splash #social{
    margin:2em 0
}
.splash .alert{
    margin:2em 0
}
.section-tout{
    padding:4em 0 3em;
    border-bottom:1px solid rgba(0,0,0,.05);
    background-color:#eaf1f1
}
.section-tout .fa{
    margin-right:.5em
}
.section-tout p{
    margin-bottom:3em
}
.section-preview{
    padding:4em 0 4em
}
.section-preview .preview{
    margin-bottom:4em;
    background-color:#eaf1f1
}
.section-preview .preview .image{
    position:relative
}
.section-preview .preview .image:before{
    box-shadow:inset 0 0 0 1px rgba(0,0,0,.1);
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    content:"";
    pointer-events:none
}
.section-preview .preview .options{
    padding:1em 2em 2em;
    border:1px solid rgba(0,0,0,.05);
    border-top:none;
    text-align:center
}
.section-preview .preview .options p{
    margin-bottom:2em
}
.section-preview .dropdown-menu{
    text-align:left
}
.section-preview .lead{
    margin-bottom:2em
}
@media (max-width:767px){
    .section-preview .image img{
        width:100%
    }
}
@media (max-width:767px){
    .splash{
        padding-top:4em
    }
    .splash .logo{
        width:100px
    }
    .splash h1{
        font-size:2em
    }
    #banner{
        margin-bottom:2em;
        text-align:center
    }
}
.container{
	margin-top: 15px;
	margin-bottom: 15px;
}

.panel-body p{
	padding-bottom: 15px;
	text-align: justify;
}
.panel-body p:last-child{
	padding-bottom: 0;
}






.feedback ol li, .review ol li{
	display: block;
	clear: both;
	list-style: none;
}
.feedback ol li label, .review ol li label{
	display: block;
	margin: 0;
}
.feedback ol li input.text, .review ol li input.text{
	width: 380px;
	border: 1px solid #c0c0c0;
	margin: 2px 0;
	padding: 5px 2px;
	height: 25px;
	background: #fff;
}
.feedback ol li textarea, .review ol li textarea{
	width: 380px;
	border: 1px solid #c0c0c0;
	margin: 2px 0;
	padding: 2px;
	background: #fff;
}
.feedback ol li input.capinput,.review ol li input.capinput{
	width: 100px;
	border: 1px solid #c0c0c0;
	margin: 2px 0;
	padding: 5px 2px;
	background: #fff;
}
.feedback ol li img#capimg, .review ol li img#capimg{
	border: none;
	top: 20px;
	margin: 10px 10px 10px 0;
	float: left;
}
li.button{
	clear: both;
} 
.item{
	display: block;
}
.answerList .name,.answerList .topic,.answerList .message{
	padding: 0 7px;
	background-color: #eef;	
}
.answerList .name{
	padding-top: 7px;
	margin-top: 15px;
	border-radius: 5px 5px 0 0;
}
.answerList .message{
	padding-bottom: 0 7px;
	border-radius: 0 0 5px 5px;
}
.answerList .answer_message{
	background: #c4c4ff;
	padding: 7px;
	border-radius: 5px;
	margin: 5px 0;
}
.name .date{
	float: right;
}
